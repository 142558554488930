import React from 'react'
import Helmet from 'react-helmet'

export const EmptyPage = () => (
  <>
    <Helmet>
      <meta name="robots" content="noindex" />
      <title />
    </Helmet>
    <div />
  </>
)
