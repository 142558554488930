import * as React from 'react'
import {
  useCMS,
  useForm,
  usePlugin,
  ActionButton,
  Modal,
  PopupModal,
  ModalHeader,
  ModalBody,
  ModalActions,
} from 'tinacms'
import { Button } from '@tinacms/styles'
import { Editor } from '../components/editor'
import { InlineForm, InlineBlocks, InlineGroup } from 'react-tinacms-inline'
import { getSectionBlocks } from '../components/section'
import { SidebarSectionBlock } from '../components/sidebarBlocks'
import { CMSWrapper } from '../components/cmsWrapper'
import { GlobalHelmet } from '../common/seo/globalHelmet'
import { EmptyPage } from '../common/seo/emptyPage'
import { ROLE_ADMIN } from '../cms/security'
import { RoleCheck } from '../components/rolecheck'
import { alertError, isEmptyString } from '../cms/validation'
import {
  refreshSourcedContent,
  refreshAndRedirectHome,
  getDeleteRefreshTimeout,
} from '../cms/refresh'
import { graphql } from 'gatsby'
import slugify from 'slugify'

const DeleteAction = ({ form }) => {
  const cms = useCMS()
  const [showPopup, setShowPopup] = React.useState(false)
  return (
    <>
      {showPopup ? (
        <Modal>
          <PopupModal>
            <ModalHeader>Referenz-Modul löschen</ModalHeader>
            <ModalBody padded={true}>
              Möchten Sie das Referenz-Modul wirklich löschen?
            </ModalBody>
            <ModalActions>
              <Button onClick={() => setShowPopup(false)}>Abbrechen</Button>
              <Button
                onClick={async () => {
                  await fetch(`/_cms/references/${form.values._id}`, {
                    method: 'DELETE',
                  })
                    .then(async () => {
                      refreshAndRedirectHome(getDeleteRefreshTimeout())
                      cms.alerts.success('Das Referenz-Modul wurde gelöscht!')
                    })
                    .catch((e) => {
                      cms.alerts.error(
                        'Fehler beim Löschen des Referenz-Moduls!'
                      )
                      console.log(e)
                    })
                  setShowPopup(false)
                }}
                primary
              >
                Löschen
              </Button>
            </ModalActions>
          </PopupModal>
        </Modal>
      ) : null}
      <RoleCheck requiredRoles={[ROLE_ADMIN]}>
        <ActionButton onClick={() => setShowPopup(true)}>
          Referenz-Modul löschen
        </ActionButton>
      </RoleCheck>
    </>
  )
}

export default function Reference({ data }) {
  const reference = data.reference

  const entityContext = {
    entityType: 'reference',
    mediaDir: reference.mediaDir,
  }

  const ReferenceForm = {
    title: 'Referenz-Modul',
    actions: [DeleteAction],
    fields: [
      {
        label: 'Modul-Titel',
        name: 'title',
        component: 'text',
      },
      {
        name: 'priority',
        label: 'Priorität',
        component: 'number',
      },
      {
        label: 'Typen',
        name: 'types',
        component: 'list',
        defaultItem: 'Start',
        field: {
          component: 'select',
          options: [
            'Start',
            'Station',
            'Blog',
            'Landingpage',
            'Cluster',
            'Bereich',
            'Pflege',
            'MFA',
            'MTLA',
            'MTRA',
          ],
        },
      },
      {
        label: 'Inhalt',
        name: 'content',
        component: 'group',
        fields: [
          {
            label: 'Sections',
            name: 'sections',
            component: 'blocks',
            templates: {
              Section: SidebarSectionBlock(entityContext),
            },
          },
        ],
      },
      {
        name: 'isDraft',
        component: 'toggle',
        label: 'Entwurf',
        description: 'Als Entwurf speichern',
      },
    ],
    initialValues: reference,
    onSubmit(formData) {
      // Field validation
      if (isEmptyString(formData.title)) {
        return alertError(cms, 'Der Modul-Titel darf nicht leer sein!')
      } else if (formData.types && checkIfDuplicateExists(formData.types)) {
        // check types duplicates
        return cms.alerts.error(
          'Speichern nicht möglich: Die Typen-Liste enthält doppelte Einträge!',
          8000
        )
      }

      cms.alerts.info('Änderungen werden gespeichert...')
      return fetch(`/_cms/references/${formData._id}`, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
        .then((response) => {
          if (response.ok) {
            cms.alerts.success(
              'Speichern erfolgreich - Seite wird neu geladen. Bitte warten!'
            )
          } else if (response.status === 401 || response.status === 403) {
            cms.alerts.error(
              'Sie haben nicht die Berechtigung, den Datensatz zu speichern!',
              5000
            )
          } else {
            response.json().then((data) => {
              console.log(
                `Updating reference id ${formData._id} failed: ` +
                  JSON.stringify(data)
              )
              const errorMessage = getUpdateErrorMessage(data)
              cms.alerts.error(errorMessage, 8000)
            })
          }
          return response.ok
        })
        .then(async (ok) => {
          if (ok === true) {
            await refreshSourcedContent()
            setTimeout(() => {
              window.location.replace(
                `${window.location.origin}/reference/${slugify(
                  formData.title.toLowerCase(),
                  {
                    locale: 'de',
                  }
                )}`
              )
            }, process.env.REFRESH_TIMEOUT_MILLIS_SAVE)
          }
        })
        .catch((e) => {
          cms.alerts.error('Fehler beim Speichern!')
          console.log('Updating reference failed: ' + e)
        })
    },
  }

  const cms = useCMS()
  const form = useForm(ReferenceForm)[1]
  usePlugin(form)

  if (cms.enabled) {
    return (
      <CMSWrapper>
        <GlobalHelmet title="UKE jukebox - Referenzmodul" />
        <main>
          <Editor form={form}>
            <InlineForm form={form}>
              <InlineGroup
                name="content"
                focusRing={{ offset: { x: -32, y: 0 }, borderRadius: 0 }}
              >
                <InlineBlocks
                  name="sections"
                  blocks={getSectionBlocks(entityContext)}
                  itemProps={{ entityContext: entityContext, entityForm: form }}
                />
              </InlineGroup>
            </InlineForm>
          </Editor>
        </main>
      </CMSWrapper>
    )
  } else {
    return <EmptyPage />
  }
}

function checkIfDuplicateExists(arr) {
  return new Set(arr).size !== arr.length
}

const getUpdateErrorMessage = (jsonResponse) => {
  // ggf. detaillierteres Fehlerhandling im Backend implementieren, spezielle Fehlercodes, so dass
  // der Fehler hier nicht geparst werden muss
  const errMsg = jsonResponse.message
  if (
    errMsg.includes('E11000 duplicate key error') &&
    (errMsg.includes('title:') || errMsg.includes('slug:'))
  ) {
    return 'Speichern nicht möglich: Dar Modul-Titel wird bereits verwendet!'
  } else {
    return 'Fehler beim Speichern!'
  }
}

export const query = graphql`
  query ReferenceQuery($id: String) {
    reference(_id: { eq: $id }) {
      _id
      isDraft
      content {
        sections {
          _template
          bgColor
          grids {
            _template
            columns {
              _template
              altText
              description
              descriptionAlignment
              showFrame
              filename
              listStyle
              text
              textAlign
              url
              href
              align
              buttonBgColor
              label
              images {
                _template
                altText
                description
                filename
                showFrame
              }
            }
            itemAlignment
            marginType
          }
        }
      }
      createdAt
      createdBy
      mediaDir
      priority
      slug
      title
      types
      updatedAt
      updatedBy
    }
  }
`
